// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cabinet-js": () => import("./../src/pages/cabinet.js" /* webpackChunkName: "component---src-pages-cabinet-js" */),
  "component---src-pages-coordonnees-js": () => import("./../src/pages/coordonnees.js" /* webpackChunkName: "component---src-pages-coordonnees-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offres-js": () => import("./../src/pages/offres.js" /* webpackChunkName: "component---src-pages-offres-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-page-js": () => import("./../src/templates/articles-page.js" /* webpackChunkName: "component---src-templates-articles-page-js" */),
  "component---src-templates-skill-js": () => import("./../src/templates/skill.js" /* webpackChunkName: "component---src-templates-skill-js" */)
}

